.removedAlert {
    animation: fade-in 0.7s ease forwards, fade-out 7s ease forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.remove-progress {
    animation: remove-progress 6s linear forwards;
    width: 100%;
}

@keyframes remove-progress {
    0% {
        width: 100%
    }
    100% {
        width: 0%;
    }
}