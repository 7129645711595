@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-15px);
    }
    50% {
        transform: translateX(15px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

.shake-div {
    animation: shake 0.6s ease-out;
}


/* Change the white to any color */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    @apply bg-birchwood/20 transition-all delay-[60000ms];
    /* This is very hacky but doesn't seem to be any other way to avoid chrome styling*/
}