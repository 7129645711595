.round-transform {
    animation: turnRound 3s forwards;
}

@keyframes turnRound {
    0% {
        border-radius: 0;
    }
    100% {
        border-radius: 50%;
    }
}

.fade-out {
    transition: opacity 0.7s ease-in-out;
    opacity: 0;
}

.fade-in {
    transition: opacity 2s ease-in-out;
    opacity: 1;
}