/* For the scrollbar track (background) */
.target-url.overflow-auto::-webkit-scrollbar-track {
  background: transparent; /* or 'rgba(0, 0, 0, 0)' for a fully transparent background */
}

/* For the scrollbar itself */
.target-url.overflow-auto::-webkit-scrollbar {
  height: 5px; /* or whatever width you prefer */
}

/* For the scrollbar thumb (the part you drag) */
.target-url.overflow-auto::-webkit-scrollbar-thumb{
    @apply bg-gunMetal/40;
  border-radius: 9999px; /* optional: to make it rounded */
}

/* For Firefox */
.target-url.overflow-auto.target-url{
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* thumb and track */
}