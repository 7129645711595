.statCardShadow {
    -webkit-box-shadow: 6px 7px 8px 3px rgba(0, 0, 0, 0.18);
    box-shadow: 6px 7px 8px 3px rgba(0, 0, 0, 0.18);
}

.statCardOnClick {
    transition: transform 0.1s ease;
}

.statCardOnClick:hover {
    transform: scale(0.98);
}

.statCardOnClick:active {
    transform: scale(0.95);
}