.firstdot-flashing {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    @apply bg-zinc-300;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0s;
}

.seconddot-flashing {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    @apply bg-zinc-300;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.3s;
}

.thirddot-flashing {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    @apply bg-zinc-300;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.7s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}


/*
.dot-flashing::before {
    left: -15px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0.5s;
}
*/

@keyframes dot-flashing {
    0% {
        @apply bg-zinc-300;
    }
    50% {
        @apply bg-zinc-400
    }
    100% {
        @apply bg-zinc-500;
    }
}

.bgcolor-wave {
    @apply bg-dandelion;
    animation: wave 1s infinite linear alternate;
    animation-delay: 0s;
}

@keyframes wave {
    0% {}
    50% {}
    100% {}
}

.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f2f2f2;
    position: relative;
    overflow: hidden;
    /* Prevent the gradient from overflowing the progress bar */
}

.progress-bar::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Set the initial width to 100% */
    background: linear-gradient(to right, #4CAF50, #4CAF50 50%, #f2f2f2 50%, #f2f2f2);
    /* Use a linear gradient to create the pulsating effect */
    background-size: 200% 100%;
    /* Set the size of the gradient to be twice the width of the progress bar */
    animation: progress-bar-animation 5s ease-in-out infinite;
}

@keyframes progress-bar-animation {
    0% {
        background-position: 100% 0;
        /* Start the animation from the left */
    }
    100% {
        background-position: -100% 0;
        /* Move the gradient to the right */
    }
}

.importing-text::after {
    content: "...";
    animation: importing-text-content 2s alternate infinite;
}

@keyframes importing-text-content {
    0% {
        content: ""
    }
    35% {
        content: "."
    }
    65% {
        content: ".."
    }
    100% {
        content: "..."
    }
}


/*#C0FFA3 50%, 

    background: linear-gradient(0.25turn, #69C53D 0%, #5ceb19 30%, #66d035 60%, #69C53D 100%);
*/

.test-progress {
    height: 1.6rem;
    background: rgb(105, 197, 61);
    background: linear-gradient(90deg, rgba(105, 197, 61, 1) 17%, rgba(138, 212, 104, 1) 43%, rgba(138, 212, 104, 1) 57%, rgba(105, 197, 61, 1) 80%);
    background-size: 200% 200%;
    background-blend-mode: luminosity;
    animation-name: progress-loading;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.progress-bar-shadow {}

.progress-bar-parent {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

@keyframes progress-loading {
    0% {
        background-position-x: 100%;
    }
    50% {
        background-position-x: 0%;
    }
    100% {
        background-position-x: -100%;
    }
}

.new-item-pulse {
    /*animation: bg-pulse-green 1s cubic-bezier(0, 0, 0.2, 1) 4;*/
    animation: bg-pulse-green 3.3s cubic-bezier(.72, .15, .36, 1.09);
}

@keyframes bg-pulse-green {
    0% {
        @apply bg-acanthusLeaf;
    }
    100% {
        @apply bg-transparent;
    }
}

.smooth-bar {
    transition: width 0.2s ease-in-out;
}

.remove-tr td:not(.ignore) {
    opacity: 0;
    transition: opacity 4s;
}

.remove-tr {
    animation: remove-animation 4s cubic-bezier(.72, .15, .36, 1.09) forwards;
}

@keyframes remove-animation {
    0% {
        @apply bg-red-400;
    }
    100% {
        @apply bg-transparent;
    }
}