@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,100&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    html {
        font-family: "Inter";
    }
}

html {
    @apply text-tamahagane;
    @apply font-normal;
    height: 100%;
    font-size: 12px;
    box-sizing: border-box;
}

html>* {
    @apply outline-none;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

input,
button,
textarea {
    @apply text-tamahagane;
    @apply font-normal;
    @apply text-medium;
    @apply outline-none;
}

input[autocomplete="off"]::-webkit-contacts-auto-fill-button,
input[autocomplete="off"]::-webkit-name-auto-fill-button,
input[autocomplete="off"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
}

button {}

div .actionBar {
    background-color: aqua !important;
}

.pageBeginMargin {
    margin-top: 9.3rem;
}

.sectionShadow {
    -webkit-box-shadow: -1px 7px 31px -7px #BCBCBC;
    box-shadow: -1px 7px 31px -7px #BCBCBC;
}

.phoneImg {
    left: 1%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 17rem;
}


/*.dropdownShadow {
    -webkit-box-shadow: -1px 7px 16px -8px rgba(78, 78, 78, 0.75);
    box-shadow: -1px 7px 16px -8px rgba(78, 78, 78, 0.75);
}*/

.dropdownShadow {
    box-shadow: 5px 5px 10px 1px rgba(196, 196, 196, 0.43);
    -webkit-box-shadow: 5px 5px 10px 1px rgba(196, 196, 196, 0.43);
    -moz-box-shadow: 5px 5px 10px 1px rgba(196, 196, 196, 0.43);
}

.showInfo {
    visibility: hidden;
}

#infoIcon:hover+.showInfo {
    visibility: visible;
}

#toast {
    animation: toastEnter;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

@keyframes toastEnter {
    0% {
        right: -100%;
    }
    100% {
        right: 0;
    }
}

span.messageLetter {
    @apply whitespace-pre-line relative opacity-0;
    animation: move-text-right 0.25s forwards;
}

@keyframes move-text-right {
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

span.comingSoonText {
    visibility: hidden;
    text-align: center;
    position: absolute;
    z-index: 50;
}

.tooltip:hover .comingSoonText {
    visibility: visible;
}

.will-change-filter {
    will-change: filter;
}

p.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #8C8F90;
    /* gunMetal */
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
}

p.custom-scrollbar::-webkit-scrollbar {
    width: 16px;
    height: 5px;
}

:root {
    --device-width: calc(16.49rem - 0rem);
}

.device {
    @apply mx-auto py-16 px-1;
    margin-top: calc(2rem);
    width: var(--device-width);
    height: calc(33.1rem + 0rem);
    border: 5px solid #A0A1A1;
    border-radius: 33px;
    background-color: #fff;
    right: 0;
    left: 0;
}

.deviceWidth {
    width: var(--device-width);
}

.device::before {
    @apply top-6;
    width: 20%;
    height: 5px;
    left: 40%;
    border-radius: 10px;
    content: '';
    background-color: #A0A1A1;
    position: absolute;
    z-index: 0;
}

.device::after {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: solid 2px #A0A1A1;
    left: calc(50% - 20px);
    bottom: 10px;
    content: '';
    position: absolute;
    z-index: 2;
}

.device header {
    @apply border-iron border rounded-t-sm flex flex-col items-center pt-2;
    z-index: 2;
}

.device section {
    @apply bg-white border border-t-0 border-iron rounded-b-sm;
    min-height: calc(100% - 70px);
    width: 100%;
    overflow: hidden;
}

:root {
    --widthxs: 69;
    --messageWXs: 43;
    --previewWXs: 25;
    --xsBreakPoint: 1230px;
}

.input-fields {
    /*     @apply w-64 4xl:w-80; */
}

.sectionGrid {
    width: calc(12px*89);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(89, 12px);
}

@media screen and (max-width: 1230px) {
    .sectionGrid {
        width: calc(12px*var(--widthxs));
        grid-template-columns: repeat(var(--widthxs), 12px);
    }
}

@media screen and (min-width: 2560px) {
    .sectionGrid {
        width: calc(12px*100);
        grid-template-columns: repeat(100, 12px);
    }
}

.sectionRows21 {
    height: calc(12px*13);
    grid-template-rows: repeat(13, 12px);
}

.messageSectionHeight {
    height: calc(12px*55);
    grid-template-rows: repeat(55, 12px);
}

.messageBoxGrid {
    width: calc(12px*58);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(58, 12px);
}

@media screen and (max-width: 1230px) {
    .messageBoxGrid {
        width: calc(12px*var(--messageWXs));
        grid-template-columns: repeat(var(--messageWXs), 12px);
    }
}

@media screen and (min-width: 2560px) {
    .messageBoxGrid {
        width: calc(12px*70);
        grid-template-columns: repeat(70, 12px);
    }
}

.previewBoxGrid {
    width: calc(12px*29);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(29, 12px);
    grid-column: span 29 / -1;
}

@media screen and (max-width: 1230px) {
    .previewBoxGrid {
        width: calc(12px*var(--previewWXs));
        grid-column: span var(--previewWXs) / -1;
        justify-content: center;
    }
}

.sectionColStart {
    @apply col-start-5;
}

.sectionRowStart {
    @apply row-start-3;
}

body {
    min-height: 100%;
}

.rootGrid {
    display: grid;
    /*     grid-template-columns: repeat( auto-fill, 12px); */
    grid-template-rows: repeat( auto-fill, 12px);
}

.scheduleBarHeight {
    height: calc(12px*30);
    grid-template-rows: repeat( auto-fill, 12px);
}

.reviewSectionHeight {
    height: calc(12px*57);
    grid-template-rows: repeat( auto-fill, 12px);
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

input[type="radio"].customRadio {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    /*     background-color: var(--form-background); */
    /* Not removed via appearance */
    font: inherit;
    color: #0097A7;
    width: 16px;
    height: 16px;
    border: 0.15em solid #0097A7;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
}

input[type="radio"].customRadio::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 12px 12px #0097A7;
    /* Windows High Contrast Mode */
    /*     background-color: CanvasText; */
}

input[type="radio"].customRadio:checked::before {
    transform: scale(1);
}


/** Flex layout **/

:root {
    /*     --sectionContainerWidth: min(80vw, 81rem); */
    --sectionContainerWidth: 887px;
}

.sectionContainerWidth {
    max-width: var(--sectionContainerWidth);
    width: var(--sectionContainerWidth);
    min-width: var(--sectionContainerWidth);
}

@media screen and (min-width: 1140px) {
    .defaultSectionWidth {
        width: calc(var(--sectionContainerWidth) / 3 * 1.8)
    }
}

.inputWidth {
    width: min(22vw, 23rem);
}

.opacityVisible {
    opacity: 1;
    transition: opacity 0.15s linear;
}

.opacityInvisible {
    opacity: 0;
    transition: opacity 0.1s linear;
}

.standardLeftMargin {
    margin-left: 4rem;
}