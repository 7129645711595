.title-text {
    animation: opacityOpen 1.1s ease forwards;
}

@keyframes opacityOpen {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
}